


























import { Component, Vue } from 'vue-property-decorator'
import Navbar from '../../components/layout/Navbar.vue'
import MeetingReport from '../../components/dashboard/report/MeetingReport.vue'
import ProspectReport from '../../components/dashboard/report/ProspectReport.vue'

@Component({
  components: {
    Navbar,
    MeetingReport,
    ProspectReport
  }
})
export default class Report extends Vue {}
