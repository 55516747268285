import Util from '@/mixins/Util'
import network from '@/services/Network'
import { Component, Mixins } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import { MeetingReportType } from '@/types/MeetingReport'
import { ProspectCurrentStatus } from '@/types/Prospect'
import FileSaver from 'file-saver'

@Component({
  name: 'MeetingReport',
  filters: {
    truncate (value: string, length: number) {
      if (!value) {
        return 'No remarks found.'
      }
      return value.length > length
        ? value.substr(0, length) + '...'
        : value
    }
  }
})
export default class MeetingReport extends Mixins(Util) {
    private isLoading = false;
    private fromDate = new Date();
    private toDate = new Date();
    private reports = new Array<MeetingReportType.Meeting>();
    private totalExpectedMargin = 0;
    private meetingType = 'complete';

    async created () {
      await this.getReports()
    }

    async getReports () {
      this.isLoading = true
      try {
        const response = await network().get(`/meeting/report?from=${DateTime.fromJSDate(this.fromDate).toSQLDate()}&to=${DateTime.fromJSDate(this.toDate).toSQLDate()}&type=${this.meetingType}`)
        this.reports = response.data.data

        //* Process data and normalize
        this.totalExpectedMargin = 0
        //* Get distinct meetings for right calculation for expected margin/projection
        const distinctProspects: any = new Object()
        this.reports.forEach(report => {
          distinctProspects[report.prospect.prospect_id] = report.prospect.projection
        })
        Object.values(distinctProspects).forEach(projection => this.totalExpectedMargin += Number(projection))
        this.reports.forEach(report => report.prospect.current_status = ProspectCurrentStatus[report.prospect.current_status as number])
        this.reports.forEach(report => report.prospect.projection = this.currencyFormatter(report.prospect.projection as number))
      } catch (error) {
      } finally {
        this.isLoading = false
      }
    }

    currencyFormatter (value: number | number | string) {
      const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'PKR',
        maximumFractionDigits: 0
      })
      return currencyFormatter.format(value as number)
    }

    handleGenerateButtonClick () {
      this.getReports()
    }

    handleMeetingReportTypeChange (value: string) {
      this.meetingType = value
      this.getReports()
    }

    exportProspectsToCsv () {
      this.isLoading = true
      if (this.reports.length <= 0) {
        this.$buefy.snackbar.open('Nothing to be exported')
        this.isLoading = false
        return
      }

      // //* Use first element to choose the keys and the order
      const keys = ['meeting_id', 'prospect', 'meeting_address', 'obd_name', 'conducted_by', 'potential', 'projected_margin', 'remarks']

      // //* Use the formatted headers (Title case and remove dashes)
      const headers = keys.map(header => header.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '))

      // //* Build header
      let result = headers.join(',') + '\n'

      // //* Add the rows
      this.reports.forEach((report) => {
        report.comments = report.comments ? report.comments : 'No remarks found.'
        result += `${report.meeting_id},${report.prospect.prospect_name},${report.meeting_address},${report.prospect.employee.full_name},${report.employee.full_name},${report.prospect.current_status},"${report.prospect.projection}",${report.comments}\n`
      })

      const blob = new Blob([result], { type: 'text/csv;charset=utf-8' })
      FileSaver.saveAs(blob, `meeting-report-(${DateTime.fromJSDate(this.fromDate).toSQLDate()})-(${DateTime.fromJSDate(this.toDate).toSQLDate()}).csv`)
      this.isLoading = false
    }
}
