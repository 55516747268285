import Util from '@/mixins/Util'
import network from '@/services/Network'
import { Component, Mixins } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import { MeetingReportType } from '@/types/MeetingReport'
import { ProspectCurrentStatus } from '@/types/Prospect'
import FileSaver from 'file-saver'

@Component({
  name: 'ProspectReport',
  filters: {
    truncate (value: string, length: number) {
      if (!value) {
        return 'No remarks found.'
      }
      return value.length > length
        ? value.substr(0, length) + '...'
        : value
    }
  }
})
export default class ProspectReport extends Mixins(Util) {
    private isLoading = false;
    private fromDate = new Date();
    private toDate = new Date();
    private reports = [];
    private totalExpectedMargin = 0;

    async created () {
      await this.getReports()
    }

    async getReports () {
      this.isLoading = true
      try {
        const response = await network().get(`/prospect/report?from=${DateTime.fromJSDate(this.fromDate).toSQLDate()}&to=${DateTime.fromJSDate(this.toDate).toSQLDate()}`)
        this.reports = response.data.data
        //* Process data and normalize
        this.reports.forEach(report => report.current_status = ProspectCurrentStatus[report.current_status as number])
        this.reports.forEach(report => report.expected_margin = this.currencyFormatter(report.expected_margin as number))
        this.reports.forEach(report => report.final_closing_margin = this.currencyFormatter(report.final_closing_margin as number))
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    }

    currencyFormatter (value: number | number | string) {
      const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'PKR'
      })
      return currencyFormatter.format(value as number)
    }

    handleGenerateButtonClick () {
      this.getReports()
    }

    exportProspectsToCsv () {
      this.isLoading = true
      if (this.reports.length <= 0) {
        this.$buefy.snackbar.open('Nothing to be exported')
        this.isLoading = false
        return
      }

      // //* Use first element to choose the keys and the order
      const keys = ['prospect_id', 'prospect_name', 'business', 'mobile_no', 'closing_date', 'final_closing_margin', 'prospect_interests', 'remarks']

      // //* Use the formatted headers (Title case and remove dashes)
      const headers = keys.map(header => header.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '))
      headers.push(...['Agent Name', 'Agent Contact', 'Agent Group'])
      // //* Build header
      let result = headers.join(',') + '\n'

      // //* Add the rows
      this.reports.forEach((report) => {
        report.remarks = report.remarks ? report.remarks : 'No remarks found.'
        report.prospect_interests = report.prospect_interests ? report.prospect_interests : 'No prospect interest found.'
        keys.forEach(key => {
          result += `"${report[key]}",`
        })
        result += `${report.employee.full_name},${report.employee.contact_no},${report.employee.group.group_name}`
        result = result.split(',').filter(el => el).join(',')
        result += '\n'
      })

      const blob = new Blob([result], { type: 'text/csv;charset=utf-8' })
      FileSaver.saveAs(blob, `prospect-report-(${DateTime.fromJSDate(this.fromDate).toSQLDate()})-(${DateTime.fromJSDate(this.toDate).toSQLDate()}).csv`)
      this.isLoading = false
    }
}
